<div class="fixed-beeadcrumb">
    <app-breadcrumb [active_item]="'Profile'" [icon]="'fas fa-user-circle-o'" [items]="[]"
                    [title]="'Profile'"
                    [url]="'/profile'"></app-breadcrumb>
</div>

<ng-template #passwordUpdateModal>
    <div class="card m-0">
        <div class="card-header custom-header">
            <div class="row">
                <div class="col-9 text-start">
                    <h5 class="modal-title font-weight-bold primary-blue-color">Update Password</h5>
                </div>
                <div class="col-3 text-end">
                    <i class="fa fa-window-close text-danger font-size-20" (click)="closeModal()"></i>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <form [formGroup]="updatePasswordForm">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                    <input type="text" class="form-control" formControlName="currentPassword"
                                           placeholder="Current Password"/>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                    <input type="text" class="form-control" formControlName="newPassword"
                                           placeholder="New Password"/>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                                    <input type="text" class="form-control" formControlName="confirmPassword"
                                           placeholder="Confirm Password"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="card-footer custom-header">
            <button (click)="closeModal()"
                    aria-label="Close"
                    class="btn btn-success color-white "
                    data-dismiss="modal">
                <i class="fas fa-pulse mr-1"></i>Close
            </button>
            <button (click)="updatePassword()"
                    aria-label="Close"
                    class="btn btn-primary color-white pull-right"
                    data-dismiss="modal">
                <i class="fa fa-lock mr-1"></i> Update Password
            </button>
        </div>
    </div>
</ng-template>

<div class="container-fluid">
    <div class="row" *ngIf="user?.user_type == 'EMPLOYEE'">
        <div class="col-sm-12 col-xl-12 col-lg-12 col-md-12  box-col-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-shadow">
                        <div class="card-header shadow-1px-light p-25px">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-between">
                                    <h5 class="color-purple">Profile</h5>
                                    <button (click)="openUpdatePasswordModal(passwordUpdateModal)"
                                            class="btn btn-primary btn-block pull-right btn-raised color-white mr-2">
                                        <i class="fa fa-lock mr-1"></i> Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body shadow-1px-light">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
                                    <div class="form-group text-center"
                                         *ngIf="selectedUser && selectedUser?.id_card != null">
                                        <img src="{{selectedUser?.id_card?.profile_image?.url}}"
                                             alt="profile"
                                             style="width: 100px; height: 100px; border-radius: 100%;"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-user-alt"></i> Name </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fas fa-id-card-alt"></i>
                                            Username </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.username}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-envelope"></i> Email </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.email}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-phone"></i> Contact </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.contact}}"/>

                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-birthday-cake"></i> Date Of
                                            Birth</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.dob}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-user-plus"></i> Date Of
                                            Joining </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.date_of_joining}}"/>

                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            <i class="fa fa-tasks"></i> Role
                                        </label>
                                        <input class="form-control shadow-1px-light  cursor-pointer" disabled readonly
                                               value="{{selectedUser?.user_detail?.role?.name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            <i class="fas fa-fax"></i> Department
                                        </label>
                                        <input class="form-control shadow-1px-light  cursor-pointer" disabled readonly
                                               value="{{selectedUser?.departmentNames}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-layer-group"></i>
                                            Designation </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.designation}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-male"></i> Gender</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.gender}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa 	fa fa-group"></i> Marital
                                            Status</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.marital_status}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-male"></i>Father Name</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.father_name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-address-card"></i> Address
                                        </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.address}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-graduation-cap"></i> Highest
                                            Qualification</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.highest_qualification}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-home"></i> Emp Number</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.emp_no}}"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-lg-block shadow-1px-light p-25px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="user?.user_type=='SUPERADMIN'">
        <div class="col-sm-12 col-xl-12 col-lg-12 col-md-12  box-col-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-shadow">
                        <div class="card-header shadow-1px-light p-25px">
                            <div class="row">
                                <div class="col-sm-12 col-md-9"><h5>Profile</h5></div>
                                <div class="col-sm-12 col-md-3 text-center">
                                    <button (click)="openUpdatePasswordModal(passwordUpdateModal)"
                                            class="btn btn-primary btn-block pull-right btn-raised color-white mr-2">
                                        <i class="fa fa-lock mr-1"></i> Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body shadow-1px-light">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
                                    <div class="form-group text-center"
                                         *ngIf="selectedUser && selectedUser?.id_card != null">
                                        <img src="{{selectedUser?.id_card?.profile_image?.url}}"
                                             alt="profile"
                                             style="width: 100px; height: 100px; border-radius: 100%;"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-user-alt"></i> Name </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fas fa-id-card-alt"></i>
                                            Username </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.username}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-envelope"></i> Email </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.email}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-phone"></i> Contact </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.contact}}"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-lg-block shadow-1px-light p-25px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="user?.user_type=='REFERRAL'">
        <div class="col-sm-12 col-xl-12 col-lg-12 col-md-12  box-col-12 ">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-shadow">
                        <div class="card-header shadow-1px-light p-25px" style="background-color: #7366ff;">
                            <div class="row">
                                <div class="col-sm-12 col-md-9 text-white"><h5>Profile</h5></div>
                                <div class="col-sm-12 col-md-3 text-center">
                                    <button (click)="openUpdatePasswordModal(passwordUpdateModal)"
                                            class="btn bg-white btn-block fw-bold pull-right btn-raised color-purple mr-2">
                                        <i class="fa fa-lock mr-1"></i> Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body shadow-1px-light py-5">

                            <form [formGroup]="profileForm">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-1">
                                        <div class="form-group text-center"
                                             *ngIf="selectedUser && selectedUser?.profile_image_url != null">
                                            <img src="{{selectedUser?.profile_image_url}}"
                                                 alt="profile"
                                                 style="width: 100px; height: 100px; border-radius: 100%;"
                                                 class="cursor-pointer"
                                                 (click)="referralProfile.click()"/>
                                            <input #referralProfile
                                                   (change)="onFileChange($event, 'REFERRAL_USER_IMAGE')"
                                                   class="form-control d-none shadow-1px-light" type="file">
                                        </div>

                                        <div class="form-group text-center"
                                             *ngIf="selectedUser && selectedUser?.profile_image_url == null">
                                            <img src="assets/images/user/user.png"
                                                 alt="profile"
                                                 style="width: 100px; height: 100px; border-radius: 100%;"
                                                 class="cursor-pointer"
                                                 (click)="referralProfile.click()"
                                            />
                                            <input #referralProfile
                                                   (change)="onFileChange($event, 'REFERRAL_USER_IMAGE')"
                                                   class="form-control-file display-hidden" type="file">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fas fa-id-card-alt"></i>
                                                Username </label>
                                            <input class="form-control shadow-1px-light disabled" disabled readonly style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   value="{{selectedUser?.username}}"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fa fa-user-alt"></i> Name </label>
                                            <input class="form-control shadow-1px-light " placeholder="Your Name" style="font-size: 13px; color: grey; padding: 12px 10px"
                                               type="text"  formControlName="name"/>
                                        </div>
                                    </div>


                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1 "><i class="fa fa-envelope"></i> Email </label>
                                            <input class="form-control shadow-1px-light " placeholder="Your Email" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"   formControlName="email"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fa fa-phone"></i> Contact </label>
                                            <input class="form-control shadow-1px-light " placeholder="Your Contact" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"    formControlName="contact"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fa fa-address-card"></i> Address
                                            </label>
                                            <input class="form-control shadow-1px-light " placeholder="Your Address" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"    formControlName="address"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fa fa-user"></i> Bank Account Name
                                            </label>
                                            <input class="form-control shadow-1px-light "
                                                   placeholder="Your Bank Account Name" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"  formControlName="bank_registered_name"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fas fa-passport"></i> Bank Account
                                                Number </label>
                                            <input class="form-control shadow-1px-light "
                                                   placeholder="Your Account Number" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"    formControlName="bank_account_number"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fa fa-bank"></i> Bank Name </label>
                                            <input class="form-control shadow-1px-light " placeholder="Your Bank Name" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"  formControlName="bank_name"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fa fa-file-text-o"></i> Bank IFSC
                                                Code </label>
                                            <input class="form-control shadow-1px-light "
                                                   placeholder="Your Bank IFSC Code" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"   formControlName="bank_ifsc_code"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label class="col-form-label primary-blue-color mt-1"><i class="fa fa-credit-card"></i> Bank UPI Id
                                            </label>
                                            <input class="form-control shadow-1px-light " placeholder="Your Bank UPI Id" style="font-size: 13px; color: grey; padding: 12px 10px"
                                                   type="text"  formControlName="bank_upi_id"/>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 mt-3">
                                        <div class="row">
                                            <div class="col-md-6 ">
                                                <div class="border d-flex w-100 justify-content-between rounded-2 pe-3 align-items-center">
                                                    <button (click)="profile.click()"
                                                            class="btn btn-primary color-white w-25 py-2 px-0"
                                                            title="Upload">
                                                        <i class="fas fa-cloud-upload-alt"></i> Add Govt. Photo ID
                                                    </button>
                                                    <input #profile (change)="onFileChange($event, 'REFERRAL_ID_IMAGE')"
                                                           class="form-control-file display-hidden"
                                                           type="file">
                                                    <a *ngIf="selectedUser?.govt_proof_url"
                                                       [href]="selectedUser?.govt_proof_url"
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i class="fa fa-eye color-purple"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer d-lg-block shadow-1px-light p-25px" style="background-color: #7366ff;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                <button class="btn btn-md bg-white fw-bold  color-purple " (click)="updateProfile()"> Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="user?.user_type == 'EMPLOYEE'">
        <div class="col-12">
            <div class="card shadow">
                <div class="card-header">
                    <h5>Assigned Devices</h5>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>sno.</th>
                                <th>Name</th>
                                <th>DeviceId</th>
                                <th>Brand</th>
                                <th>Model</th>
                                <th>Serial Number</th>
                                <th>Color</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of devices; let i = index;">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item?.device?.name }}</td>
                                <td>{{ item?.device?.device_id }}</td>
                                <td>{{ item?.device?.brand }}</td>
                                <td>{{ item?.device?.model }}</td>
                                <td>{{ item?.device?.serial_number }}</td>
                                <td>{{ item?.device?.color }}</td>
                                <td>{{ item?.assign_status == 'ACTIVE' ? 'ASSIGNED' : 'REMOVED' }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row"></div>
                </div>
            </div>
        </div>
    </div>
</div>
