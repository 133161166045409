<!--<div class="login-page">-->
<!--    &lt;!&ndash; Three.js animation container &ndash;&gt;-->
<!--    <div id="threejs-container"></div>-->

<!--    &lt;!&ndash; Centered Login Form &ndash;&gt;-->
<!--    <div class="login-form-container">-->
<!--        <div class="row ">-->
<!--            <div class="col-md-6 d-sm-none d-md-block mb-0">-->
<!--                <div class="quote-container">-->
<!--                    <blockquote class="blockquote text-start">-->
<!--                        <p class="mb-0 text-white font-size-20">"{{ quote }}"</p>-->
<!--                        <footer class="blockquote-footer text-white m-1">{{ author }}</footer>-->
<!--                    </blockquote>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-md-6 mt-0">-->
<!--                <div class="login-card">-->
<!--                    <div>-->
<!--                        <div class="login-main">-->
<!--                            <div>-->
<!--                                <a class="logo" routerLink="/">-->
<!--                                    <img class="img-fluid for-light" src="assets/images/logo/logo.png"-->
<!--                                         alt="looginpage"/>-->
<!--                                    <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png"-->
<!--                                         alt="looginpage"/>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                            <form class="theme-form" [formGroup]="loginForm">-->
<!--                                <h4>Sign in to account</h4>-->
<!--                                <p>Enter your username & password to login</p>-->
<!--                                <div class="form-group">-->
<!--                                    <label class="col-form-label">Username</label>-->
<!--                                    <input class="form-control" type="text" placeholder="Volmint"-->
<!--                                           formControlName="username"/>-->
<!--                                    <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required"-->
<!--                                         class="text text-danger mt-1">Username is required-->
<!--                                    </div>-->
<!--                                    <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.lowercase"-->
<!--                                         class="text text-danger mt-1">-->
<!--                                        Username must be in lowercase letters only.-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="form-group">-->
<!--                                    <label class="col-form-label">Password</label>-->
<!--                                    <input class="form-control" [type]="show ? 'text' : 'password'"-->
<!--                                           formControlName="password" placeholder="*********"/>-->
<!--                                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span-->
<!--                                            class="show"></span>-->
<!--                                    </div>-->
<!--                                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span-->
<!--                                            class="Hide"></span>-->
<!--                                    </div>-->
<!--                                    <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"-->
<!--                                         class="text text-danger mt-1">Password is required-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="form-group mb-0">-->
<!--                                    <div class="checkbox p-0">-->
<!--                                        <input id="checkbox1" type="checkbox"/>-->
<!--                                        <label class="text-muted" for="checkbox1">Remember password</label>-->
<!--                                    </div>-->
<!--                                    <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot-->
<!--                                        password?</a>-->
<!--                                    <div class="text-end mt-3">-->
<!--                                        <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid"-->
<!--                                                (click)="login()" type="button"><span>Sign in</span></button>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </form>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->


<section class="h-100 gradient-form" style="background-color: #FBF5DD;">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-xl-10">
                <div class="card rounded-3 text-black">
                    <div class="row g-0">
                        <div class="col-lg-6">
                            <div class="card-body p-md-5 mx-md-4">

                                <div class="text-center">
                                    <img src="assets/images/volmint_logo.jpeg"
                                         style="width: 100px;" alt="logo">
                                    <h4 class="mt-1 mb-5 pb-1">We are Volmint Technologies</h4>
                                </div>

                                <form [formGroup]="loginForm">
                                    <p>Please login to your account</p>

                                    <div data-mdb-input-init class="form-outline mb-4">
                                        <input type="email" id="form2Example11" class="form-control"
                                               placeholder="Your {{user_type=='ADMIN' ? 'Username' : 'Email'}}"
                                               formControlName="username"/>
                                        <label class="form-label"
                                               for="form2Example11">{{ user_type == 'ADMIN' ? 'Username' : 'Email' }}</label>
                                    </div>

                                    <div data-mdb-input-init class="form-outline  mb-4">
                                        <div class="input-group">
                                            <input type="{{show?  'text' : 'password'}}" id="form2Example22"
                                                   class="form-control"
                                                   placeholder="{{ user_type == 'ADMIN' ? 'Your Password' : 'Your password may contact' }}"
                                                   formControlName="password"/>
                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="showPassword()">
                                                    <i *ngIf="show == true" class="fa fa-eye text-secondary fs-6"></i>
                                                    <i *ngIf="show == false"
                                                       class="fa fa-eye-slash text-secondary fs-6"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <label class="form-label" for="form2Example22">Password</label>
                                    </div>

                                    <div class="text-center pt-1 mb-5 pb-1">
                                        <button (click)="login()" data-mdb-button-init data-mdb-ripple-init
                                                class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3"
                                                type="button"> {{ user_type == 'ADMIN' ? 'Employee Login' : 'Partner Login' }}
                                        </button>
                                        <!--                                        <a class="text-muted" href="#!">Forgot password?</a>-->
                                    </div>

                                    <div class="d-flex align-items-center justify-content-center pb-4">
                                        <p class="mb-0 me-2">{{ user_type == 'ADMIN' ? 'Are you a valued partner?' : 'Are you an active employee?' }}</p>
                                        <button (click)="changeUserType()" type="button" data-mdb-button-init
                                                data-mdb-ripple-init
                                                class="btn btn-outline-danger">{{ user_type == 'ADMIN' ? 'Partner Login' : 'Employee Login' }}
                                        </button>
                                    </div>

                                </form>

                            </div>
                        </div>
                        <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
                            <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                                <h4 class="mb-4">We are more than just a company</h4>
                                <p class="small mb-0">Increase productivity and decrease errors! Our solutions integrate
                                    effortlessly with your existing systems, improving the efficiency of all
                                    administrative tasks in your organization.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

