import {Component, PLATFORM_ID, Inject, OnInit, OnDestroy, HostListener} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Subject} from 'rxjs';
import {StorageService} from "./shared/services/storage.service";
import {GlobalService} from "./shared/services/global.service";
import {AuthService} from "./shared/services/auth.service";
import {Router} from "@angular/router";
import {UserService} from "./shared/services/user.service";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import {DataService} from "./shared/services/data.service";
import {WindowEventService} from "./shared/services/window-event.service";
import {GroupChatDataServiceService} from "./shared/services/group-chat-data-service.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
    user: any;
    userData: any;
    private echo: Echo;
    window = {Pusher: null};
    private _unsubscribeAll: Subject<any>;
    userInteracted = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private loader: LoadingBarService,
                private authService: AuthService,
                private router: Router,
                private userService: UserService,
                private globalService: GlobalService,
                private dataService: DataService,
                private groupChatDataService: GroupChatDataServiceService,
                private WindowEventService: WindowEventService) {
        this.user = StorageService.getItem('self');
        this._unsubscribeAll = new Subject();
        this.checkLogin();

        this.globalService.reloadSelf.subscribe((d) => {
            if (d) {
                this.reloadSelf();
                this.globalService.reloadSelf.next(false);
            }
        });
    }

    ngOnInit(): void {
        this.getUserDetail();

        document.addEventListener('click', () => {
            this.userInteracted = true;
        }, {once: true});
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(this.user);
        this._unsubscribeAll.complete();
    }

    getUserDetail() {
        if (this.user && this.user.user_id) {
            this.userService.detail(this.user.user_id).subscribe(data => {
                if (data) {
                    this.userData = data;
                    this.updateLoginStatusActive();
                }
            });
        }
    }

    updateLoginStatusActive() {
        if (this.userData && this.userData.unique_id) {
            this.userService.loginStatus(this.userData.unique_id, {'is_login_web': 'ACTIVE'}).subscribe(data => {
            }, error => {
            });
        } else {
        }
    }

    checkLogin() {
        if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
            this.globalService.setAccessToken(StorageService.getItem('accessToken'));
            this.globalService.setSelf(StorageService.getItem('self'));
            this.reloadSelf();
        } else {
            this.router.navigateByUrl('auth/login');
        }
    }

    reloadSelf() {
        this.authService.self({all: true}).subscribe((data) => {
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type,
                'unique_id': data.unique_id
            });
            this.connectToSocket();
            return;
        }, e => {
            StorageService.clearAll();
            this.router.navigateByUrl('auth/login');
        });
    }

    connectToSocket() {
        this.window.Pusher = Pusher;
        this.echo = new Echo({
            broadcaster: 'pusher',
            key: '29f3edbd069470d91803',
            cluster: 'ap2',
            // wsHost: window.location.hostname,
            // wsPort: 6001,
            forceTLS: true,
            // disableStats: true,
        });
        this.listenToChat();
    }

    listenToChat() {
        this.echo.channel(`chat_app_${this.user?.user_id}`)
            .listen('.message.sent', (data: any) => {
                console.log('single  ',data )
                this.dataService.sendMessage(data);
                this.playNotificationSound();
            });
        this.listenToGroupChat();
    }

    listenToGroupChat() {
        this.echo.channel(`group_chat_app_${this.user?.user_id}`)
            .listen('.message.sent', (data: any) => {
                console.log('group ',data )
                this.groupChatDataService.sendMessage(data);
                this.playNotificationSound();
            });
    }

    playNotificationSound() {
        // const audio = new Audio();
        // audio.src = 'assets/sounds/notification.mp3'; // Path to your sound file
        // audio.load();
        // audio.play();
        if (this.userInteracted) {
            const audio = new Audio('assets/sounds/notification.mp3');
            audio.play().catch(error => console.error('Failed to play audio:', error));
        } else {
            console.warn('Sound not played: user has not interacted with the document yet.');
        }
    }
}
