import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data = {}): Observable<any> {
        return this.getRequest(AppUrl.USER());
    }

    getPartnerOverView(data) : Observable<any> {
        return this.getRequest(AppUrl.PARTNER_OVERVIEW(), data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.USER(id));
    }

    update(id): Observable<any> {
        return this.putRequest(AppUrl.USER(id));
    }

    updatePassword(data): Observable<any> {
        return this.putRequest(AppUrl.SELF_PROFILE_PASSWORD(), data);
    }

    self(): Observable<any> {
        return this.getRequest(AppUrl.SELF);
    }

    loginStatus(uniqueId: any, data: any): Observable<any> {
        return this.putRequest(AppUrl.LOGIN_STATUS(uniqueId), data);
    }


}
