import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {BaseService} from "./base.service";
import {AppUrl} from "../constants/app-url";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PartnerService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    login(data: any): Observable<any> {
        return this.postRequest(AppUrl.PARTNER_LOGIN(), data)
    }

    self(data: any): Observable<any> {
        return this.getRequest(AppUrl.PARTNER_SELF(), data)
    }

    get(data: any): Observable<any> {
        return this.getRequest(AppUrl.PARTNER_GET(), data)
    }

    detail(unique_id:any): Observable<any> {
        return this.getRequest(AppUrl.PARTNER(unique_id))
    }

    getTransaction(data: any): Observable<any> {
        return this.getRequest(AppUrl.PARTNER_TRANSACTION(), data)
    }

    delete(uniqueId: any): Observable<any> {
        return this.deleteRequest(AppUrl.PARTNER(uniqueId));
    }

    updateStatus(uniqueId: any, data: any): Observable<any> {
        return this.putRequest(AppUrl.PARTNER_STATUS(uniqueId), data);
    }

    addTransaction(data: any): Observable<any> {
        return this.postRequest(AppUrl.PARTNER_TRANSACTION(), data)
    }

    updateTransaction(unique_id: any, data: any): Observable<any> {
        return this.putRequest(AppUrl.PARTNER_TRANSACTION(unique_id), data)
    }

    updateTransactionStatus(unique_id: any, data: any): Observable<any> {
        return this.putRequest(AppUrl.PARTNER_TRANSACTION_STATUS(unique_id), data)
    }

    addSaleAmount(unique_id: any, data: any): Observable<any> {
        return this.putRequest(AppUrl.PARTNER_SALE_AMOUNT(unique_id), data)
    }

    deleteTransaction(unique_id: any): Observable<any> {
        return this.deleteRequest(AppUrl.PARTNER_TRANSACTION(unique_id));
    }


}
