import {Injectable, OnDestroy} from "@angular/core";
import {Subject, BehaviorSubject, fromEvent} from "rxjs";
import {takeUntil, debounceTime} from "rxjs/operators";
import {Router} from "@angular/router";
import {PermissionConstant} from '../constants/permission-constant';
import {StorageService} from "./storage.service";
import {Helper} from "../constants/helper";
import {RolePermissionService} from "./role-permission.service";
import {AccessService} from "./access.service";

// Menu
export interface Menu {
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    availableTo?: string[];
    bookmark?: boolean;
    children?: Menu[];
    isOpen?: boolean;
    is_hidden?: any;
}

@Injectable({
    providedIn: "root",
})
export class NavService implements OnDestroy {
    private unsubscriber: Subject<any> = new Subject();
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    // Search Box
    public search: boolean = false;

    // Language
    public language: boolean = false;

    // Mega Menu
    public megaMenu: boolean = false;
    public levelMenu: boolean = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199;

    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991;

    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth >= 991;

    // Full screen
    public fullScreen: boolean = false;
    user: any;
    userData: any;

    constructor(private router: Router,
                private permissionService: AccessService) {
        this.user = StorageService.getItem('self');
        if (this.user.user_type == 'EMPLOYEE') {
            this.getUserDetail();
        } else if (this.user.user_type == 'REFERRAL') {
            this.hideAllMenus();
        }
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, "resize")
            .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
            .subscribe((evt: any) => {
                this.setScreenWidth(evt.target.innerWidth);
                if (evt.target.innerWidth < 991) {
                    this.collapseSidebar = true;
                    this.megaMenu = false;
                    this.levelMenu = false;
                }
                if (evt.target.innerWidth < 1199) {
                    this.megaMenuColapse = true;
                }
            });
        if (window.innerWidth < 991) {
            // Detect Route change sidebar close
            this.router.events.subscribe((event) => {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            });
        }
    }

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    MENUITEMS: Menu[] = [
        /*{
            headTitle1: "Pages",
        },*/
        {
            path: "/dashboard",
            icon: "fas fa-home",
            title: "Dashboard",
            type: "link",
            //@ts-ignore
            availableTo: [PermissionConstant.ALL],
            bookmark: true
        },
        {
            icon: "fa-user-shield fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Employees",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_EMPLOYEE, PermissionConstant.LIST_EMPLOYEE],
            children: [
                {
                    path: "/employee/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_EMPLOYEE],
                },
                {
                    path: "/employee/list",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_EMPLOYEE],
                },
                {
                    path: "/employee/id-card",
                    title: "Id Card",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_EMPLOYEE],
                },

            ],
        },
        // {
        //     icon: "fa-user-shield fas",
        //     type: "sub",
        //     badgeType: "light-primary",
        //     title: "Employee",
        //     active: false,
        //     //@ts-ignore
        //     availableTo: ["SUPERADMIN"],
        //     children: [
        //         {
        //             path: "/employee/add",
        //             title: "Add",
        //             type: "link",
        //             //@ts-ignore
        //             availableTo: ["SUPERADMIN"],
        //         },
        //         {
        //             path: "/employee",
        //             title: "List",
        //             type: "link",
        //             //@ts-ignore
        //             availableTo: ["SUPERADMIN"],
        //         }
        //     ],
        // },
        {
            icon: "fa-bar-chart fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Career",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_CAREER, PermissionConstant.LIST_CAREER],
            children: [
                {
                    path: "/career/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_CAREER],
                },
                {
                    path: "/career",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_CAREER],
                }
            ],
        },
        {
            icon: "fa-blog fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Blog",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_BLOG, PermissionConstant.LIST_BLOG],
            children: [
                {
                    path: "/blog/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_BLOG],
                },
                {
                    path: "/blog",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_BLOG],
                }
            ],
        },
        // {
        //     icon: "fa-tags fas",
        //     type: "sub",
        //     badgeType: "light-primary",
        //     title: "Meta Tags",
        //     active: false,
        //     //@ts-ignore
        //     availableTo: [PermissionConstant.ADD_META_TAG, PermissionConstant.LIST_META_TAG],
        //     children: [
        //         {
        //             path: "/meta-tags/add",
        //             title: "Add",
        //             type: "link",
        //             //@ts-ignore
        //             availableTo: [PermissionConstant.ADD_META_TAG],
        //         },
        //         {
        //             path: "/meta-tags",
        //             title: "List",
        //             type: "link",
        //             //@ts-ignore
        //             availableTo: [PermissionConstant.LIST_META_TAG],
        //         }
        //     ],
        // },
        {
            icon: "fa-solid fa-building  fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Department",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_DEPARTMENT, PermissionConstant.LIST_DEPARTMENT, PermissionConstant.ADD_USER_DEPARTMENT, PermissionConstant.LIST_USER_DEPARTMENT],
            children: [
                {
                    path: "/departments/add-department",
                    title: "Add Department",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_DEPARTMENT],
                },
                {
                    path: "/departments/list-department",
                    title: "List Department",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_DEPARTMENT],
                },
                // {
                //     path: "/departments/add-user",
                //     title: "Add User",
                //     type: "link",
                //     //@ts-ignore
                //     availableTo: [PermissionConstant.ADD_USER_DEPARTMENT],
                // },
                // {
                //     path: "/departments/list-user",
                //     title: "List User",
                //     type: "link",
                //     //@ts-ignore
                //     availableTo: [PermissionConstant.LIST_USER_DEPARTMENT],
                // }
            ],
        },
        {
            icon: "fa-globe fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Access",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_ACCESS, PermissionConstant.LIST_ACCESS],
            children: [
                {
                    path: "/access/roles",
                    title: "Roles",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_ACCESS],
                },
                {
                    path: "/access/permissions",
                    title: "Permissions",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_ACCESS],
                }
            ],
        },
        {
            path: "/contact-us",
            icon: "fas fa-phone",
            title: "Contact ",
            type: "link",
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_CONTACT, PermissionConstant.LIST_CONTACT],
            bookmark: true
        },
        {
            path: "/career-data",
            icon: "fas fa-graduation-cap",
            title: "Applicant Resumes",
            type: "link",
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_CAREER, PermissionConstant.LIST_CAREER],
            bookmark: true
        },
        {
            icon: "fas fa-person",
            type: "sub",
            badgeType: "light-primary",
            title: "Attendance",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_ATTENDANCE, PermissionConstant.LIST_ATTENDANCE],
            children: [
                {
                    path: "/attendance/today-attendance",
                    title: "Today's Attendance",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_ATTENDANCE],
                },
                {
                    path: "/attendance/employee-attendance",
                    title: "Employee Attendance",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_ATTENDANCE],
                },
                {
                    path: "/attendance/monthly-sheet",
                    title: "Attendance Sheet",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_ATTENDANCE],
                }
            ],
        },
        {
            path: "/leave",
            icon: "fas fa-file",
            title: "Leaves",
            type: "link",
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_LEAVE, PermissionConstant.LIST_LEAVE],
            bookmark: true
        },
        {
            path: "/holiday",
            icon: "fas fa-snowman",
            title: "Holiday",
            type: "link",
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_HOLIDAY, PermissionConstant.LIST_HOLIDAY],
            bookmark: true
        },
        // {
        //     path: "/profile",
        //     icon: "fas fa-user-circle-o",
        //     title: "Profile",
        //     type: "link",
        //     //@ts-ignore
        //     availableTo: [PermissionConstant.ADD_PROFILE, PermissionConstant.LIST_PROFILE],
        //     bookmark: true
        // },
        {
            icon: "fa-microchip fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Device",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_DEVICE, PermissionConstant.LIST_DEVICE],
            children: [
                {
                    path: "/device/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_DEVICE],
                },
                {
                    path: "/device",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_DEVICE],
                }
            ],
        },
        {
            icon: "fa fa-paypal",
            type: "sub",
            badgeType: "light-primary",
            title: "Billing",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.LIST_TEMPLATE, PermissionConstant.ADD_INVOICE, PermissionConstant.EDIT_INVOICE, PermissionConstant.LIST_INVOICE],
            children: [
                {
                    path: "/transaction/template",
                    title: "Template",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_TEMPLATE],
                },
                {
                    path: "/transaction/invoice",
                    title: "Invoice",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_INVOICE, PermissionConstant.EDIT_INVOICE, PermissionConstant.LIST_INVOICE],
                },
                {
                    path: "/transaction/proforma-invoice",
                    title: "Proforma Invoice",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.ADD_INVOICE, PermissionConstant.EDIT_INVOICE, PermissionConstant.LIST_INVOICE],
                }
            ],
        },
        {
            icon: "fa fa-handshake-o",
            type: "sub",
            badgeType: "light-primary",
            title: "Partner",
            active: false,
            //@ts-ignore
            availableTo: [PermissionConstant.LIST_PARTNER, PermissionConstant.TRANSACTION_PARTNER],
            children: [
                {
                    path: "/partner",
                    title: "Partners",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.LIST_PARTNER],
                },
                {
                    path: "/partner/transaction",
                    title: "Transactions",
                    type: "link",
                    //@ts-ignore
                    availableTo: [PermissionConstant.TRANSACTION_PARTNER],
                },
            ],
        },
        {
            path: "/chat",
            icon: "fas fa-comments",
            title: "Chat",
            type: "link",
            //@ts-ignore
            availableTo: [PermissionConstant.ADD_CHAT, PermissionConstant.LIST_CHAT],
            bookmark: true
        },
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

    getUserDetail() {
        let user_id = JSON.parse(localStorage.getItem('self')).user_id;

        this.permissionService.RolePermissionGet({'userid': user_id}).subscribe(data => {
            if (data && data['data']) {

                this.userData = data['data'];
                if (this.userData) {
                    let rolePermissions = [];
                    for (let i = 0; i < (this.userData).length; i++) {
                        [this.userData[i].sub_permission].forEach(subPermission => {
                            if (subPermission && subPermission['meta'] && subPermission['meta']) {
                                rolePermissions.push(subPermission['meta']);
                            }
                        });
                    }
                    this.MENUITEMS.forEach(menu => {
                        if (menu && menu.availableTo) {
                            if (!menu['availableTo'].includes('ALL')) {
                                let checkMenuPermission = Helper.findCommonElements(menu.availableTo, rolePermissions);
                                menu['is_hidden'] = !checkMenuPermission;
                                if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
                                    if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                                        menu['children'][0]['children'].forEach(child => {
                                            let checkSubMenuPermission = Helper.findCommonElements(child.availableTo, rolePermissions);
                                            child['is_hidden'] = !checkSubMenuPermission;
                                        });
                                    }
                                }
                            } else {
                                menu['is_hidden'] = false;
                            }
                        } else {
                            menu['is_hidden'] = true;
                        }
                    });
                }
            }
        });
    }

    hideAllMenus() {
        this.MENUITEMS.forEach(menu => {
            if (menu && menu.availableTo) {
                if (!menu['availableTo'].includes('ALL') && !(menu['availableTo'].includes('PARTNER_LIST_PARTNER')) && !(menu['availableTo'].includes('PARTNER_TRANSACTION_PARTNER'))) {
                    // let checkMenuPermission = Helper.findCommonElements(menu.availableTo, rolePermissions);
                    menu['is_hidden'] = true;
                    if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
                        if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                            menu['children'][0]['children'].forEach(child => {
                                // let checkSubMenuPermission = Helper.findCommonElements(child.availableTo, rolePermissions);
                                child['is_hidden'] = true;
                            });
                        }
                    }
                } else {
                    menu['is_hidden'] = false;
                }
            } else {
                menu['is_hidden'] = true;
            }
        });
    }
}
