import {Component, ElementRef, HostListener, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../../shared/services/storage.service";
import {GlobalService} from "../../shared/services/global.service";
import {AuthService} from "../../shared/services/auth.service";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import {DataService} from "../../shared/services/data.service";
import {UserService} from "../../shared/services/user.service";
import {AppComponent} from "../../app.component";
import * as THREE from 'three';
import randomQuote from 'random-quotes';
import {GroupChatDataServiceService} from "../../shared/services/group-chat-data-service.service";
import {PartnerService} from "../../shared/services/partner.service"; // Import the package
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {
    @ViewChild('threejsContainer', {static: true}) threejsContainer!: ElementRef;
    public loginForm: FormGroup;
    public show: boolean = false
    submittedLogin: any = false;
    isBusy: any = false;
    private echo: Echo;
    window = {Pusher: null};
    user: any;
    userData: any;
    quote: string = ''; // To store the quote
    author: string = ''; // To store the author of the quote
    user_type = 'ADMIN';
    constructor(private fb: FormBuilder,
                public router: Router,
                private route: ActivatedRoute,
                private globalService: GlobalService,
                private authService: AuthService,
                private dataService: DataService,
                private userService: UserService,
                private appComponent: AppComponent,
                private groupChatDataService: GroupChatDataServiceService,
                private partnerService: PartnerService) {
        this.checkLogin();
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.loginForm = this.fb.group({
            username: ['', [
                Validators.required,
                (control) => control.value && control.value !== control.value.toLowerCase() ? {lowercase: true} : null
            ]],
            password: ["", Validators.required],
            login: ['WEB']
        });
    }


    checkLogin() {
        if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
            this.globalService.setAccessToken(StorageService.getItem('accessToken'));
            this.globalService.setSelf(StorageService.getItem('self'));
            this.user = StorageService.getItem('self');
            this.reloadSelf();
        } else {
            this.router.navigateByUrl('auth/login');
        }
    }

    reloadSelf() {
        this.authService.self({all: true}).subscribe((data) => {
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type,
                'unique_id': data.unique_id
            });
            this.router.navigateByUrl('/dashboard');
        }, e => {
            StorageService.clearAll();
            this.router.navigateByUrl('auth/login');
        });
    }

    login() {
        this.submittedLogin = true;
        if (!this.loginForm.valid) {
            return;
        }
        if (this.submittedLogin) {
            if (this.checkLoggedInStatus()) {
                this.setSelfData();
                this.connectToSocket();
                this.router.navigateByUrl('/dashboard');
            } else {
                if(this.user_type == 'ADMIN') {
                    this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
                        if (data.id) {
                            this.globalService.setAccessToken(data.token);
                            this.globalService.setSelf({
                                'name': data.name,
                                'email': data.email,
                                'contact': data.contact,
                                'user_id': data.id,
                                'username': data.username,
                                'user_type': data.user_type,
                                'unique_id': data.unique_id
                            });
                            this.user = StorageService.getItem('self');
                            this.connectToSocket();
                            this.router.navigateByUrl('/dashboard');
                            this.loginForm.reset();
                        }
                    }, error => {
                        this.isBusy = false;
                        this.submittedLogin = false;
                    });
                }else if(this.user_type == 'PARTNER'){
                    this.partnerService.login(this.loginForm.getRawValue()).subscribe(data => {
                        if (data.id) {
                            this.globalService.setAccessToken(data.token);
                            this.globalService.setSelf({
                                'name': data.name,
                                'email': data.email,
                                'contact': data.contact,
                                'user_id': data.id,
                                'username': data.username,
                                'user_type': data.user_type,
                                'unique_id': data.unique_id
                            });
                            this.user = StorageService.getItem('self');
                            this.connectToSocket();
                            this.router.navigateByUrl('/dashboard');
                            this.loginForm.reset();
                        }
                    }, error => {
                        this.isBusy = false;
                        this.submittedLogin = false;
                    })
                }
            }
        }
    }

    showPassword() {
        this.show = !this.show
    }

    checkLoggedInStatus() {
        const accessToken = StorageService.getItem('accessToken');
        return accessToken && accessToken !== 'null';
    }

    setSelfData() {
        this.authService.self({all: true}).subscribe((data) => {
            this.isBusy = false;
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type
            });
            return;
        }, e => {
            this.isBusy = false;
            StorageService.clearAll();
        });
    }

    connectToSocket() {
        this.window.Pusher = Pusher;
        this.echo = new Echo({
            broadcaster: 'pusher',
            key: '29f3edbd069470d91803',
            cluster: 'ap2',
            // wsHost: window.location.hostname,
            // wsPort: 6001,
            forceTLS: true,
            // disableStats: true,
        });
        this.listenToChat();
    }

    listenToChat() {
        this.echo.channel(`chat_app_${this.user?.user_id}`)
            .listen('.message.sent', (data: any) => {
                console.log('single  ', data)
                this.dataService.sendMessage(data);
            });
        this.listenToGroupChat();
    }

    listenToGroupChat() {
        this.echo.channel(`group_chat_app_${this.user?.user_id}`)
            .listen('.message.sent', (data: any) => {
                console.log('group ', data)
                this.groupChatDataService.sendMessage(data);
                this.playNotificationSound();
            });
    }

    playNotificationSound() {
        const audio = new Audio();
        audio.src = 'assets/sounds/notification.mp3'; // Path to your sound file
        audio.load();
        audio.play();
    }

    changeUserType(){
        if(this.user_type == 'ADMIN'){
            this.user_type = 'PARTNER';
        }else{
            this.user_type = 'ADMIN';
        }
    }
}
