import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AccessService} from "../../shared/services/access.service";
import {NavService} from "../../shared/services/nav.service";
import {StorageService} from "../../shared/services/storage.service";
import {Helper} from "../../shared/constants/helper";
import {FileService} from "../../shared/services/file.service";
import {AlertService} from "../../shared/services/alert.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserService} from "../../shared/services/user.service";
import {UsersService} from "../../shared/services/users.service";
import {AuthService} from "../../shared/services/auth.service";
import {DeviceService} from "../../shared/services/device.service";
import {DepartmentService} from "../../shared/services/department.service";
import {ProfileDataService} from "../../shared/services/profile-data.service";


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    user: any;
    userData: any;
    subPermissionArray: any;
    updatePasswordForm: FormGroup;
    profileForm: FormGroup;
    submitted = null;
    onFileChanged: any;
    imageId = null;
    selectedUser: any;
    devices: any;
    referralIdImg : any;
    departments: any;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private permissionService: AccessService,
                private navService: NavService,
                private fileService: FileService,
                private alertService: AlertService,
                private modalService: NgbModal,
                private userService: UserService,
                private usersService: UsersService,
                // private dataService: DataService,
                private authService: AuthService,
                private deviceService: DeviceService,
                private departmentService: DepartmentService,
                private profileDataService: ProfileDataService) {
        this.user = StorageService.getItem('self');
    }

    ngOnInit() {
        if (this.user.user_type == 'EMPLOYEE') {
            this.getUserDetail();
        }
        this.updatePasswordForm = this.fb.group({
            'currentPassword': ['', Validators.required],
            'newPassword': ['', Validators.required],
            'confirmPassword': ['', Validators.required]
        });
        this.profileForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            contact: ['', Validators.required],
            address: ['', Validators.required],
            bank_registered_name: ['', Validators.required],
            bank_account_number: ['', Validators.required],
            bank_name: ['', Validators.required],
            bank_ifsc_code: ['', Validators.required],
            bank_upi_id: ['', Validators.required],
        })
        if (this.user.user_type == 'SUPERADMIN' || this.user.user_type == 'EMPLOYEE') {
            this.refresh();
        } else {
            this.getReferral();
        }

    }

    getUserDetail() {
        if (this.user && this.user.user_id) {
            this.permissionService.RolePermissionGet({'userid': this.user.user_id}).subscribe(data => {
                if (data) {
                    this.userData = data.data;
                    let subPermissionsArr = [];
                    if (this.userData) {
                        this.userData.forEach(rolePermission => {
                            subPermissionsArr.push(rolePermission['sub_permission']['meta']);
                        });
                        this.subPermissionArray = subPermissionsArr;
                    }
                    this.getNavService(this.router.url);
                }
            });
        }
    }

    getNavService(url) {
        this.navService.MENUITEMS.forEach(menu => {
            if (menu && menu.availableTo) {
                if (menu['path'] == url) {
                    let checkMenuPermission = Helper.findCommonElements(menu.availableTo, this.subPermissionArray);
                    if (!checkMenuPermission) {
                        this.router.navigateByUrl('/dashboard');
                    }
                } else {
                    menu.children?.forEach(child => {
                        if (child.path == url) {
                            let checkSubMenuPermission = Helper.findCommonElements(child.availableTo, this.subPermissionArray);
                            if (!checkSubMenuPermission) {
                                this.router.navigateByUrl('/dashboard');
                            }
                        }
                    })
                }
            }
        })
    }

    refresh() {
        this.getDepartments();
    }

    getDepartments() {
        this.departmentService.get({}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.departments = data['data'];
            }
        });
        this.get();
    }

    get() {
        this.userService.self().subscribe(data => {
            this.usersService.detail(data.unique_id).subscribe(data => {
                this.selectedUser = data;
                console.log('profileData', this.selectedUser)
                let departmentIds = this.selectedUser?.user_detail?.department_ids?.split(',');
                let selectedDepartments = [];
                departmentIds.forEach((item: any) => {
                    this.departments.forEach(department => {
                        if (department.id == item) {
                            selectedDepartments.push(department.name);
                        }
                    })
                })
                this.selectedUser.departmentNames = selectedDepartments.join(', ');
            });
        });
        this.getAssignedDevices();
    }

    getAssignedDevices() {
        this.deviceService.getAssigned({'employee_id': this.user?.user_id}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.devices = data['data'];
            }
        })
    }

    onFileChange(e, type) {
        if (type === 'PROFILE_IMAGE') {
            this.onFileChanged = e;
            this.fileService.fileUpload({
                'type': type,
                'fileType': 'NORMAL',
            }, this.onFileChanged).subscribe(data => {
                if (data) {
                    this.imageId = data.data;

                    // this.dataService.sendMessage(this.imageId.url);
                } else {
                    this.alertService.showErrors('File Not Uploaded!');
                }

            }, error => {
                this.alertService.showErrors('Something went wrong, please try again');
            });
        } else if (type === 'REFERRAL_USER_IMAGE') {
            this.onFileChanged = e;
            this.fileService.fileUpload({
                'type': type,
                'fileType': 'NORMAL',
            }, this.onFileChanged).subscribe(data => {
                if (data) {
                    this.imageId = data.data;
                    this.updateReferralProfile(data.data);
                } else {
                    this.alertService.showErrors('File Not Uploaded!');
                }

            }, error => {
                this.alertService.showErrors('Something went wrong, please try again');
            });
        }
        else if (type === 'REFERRAL_ID_IMAGE') {
            this.onFileChanged = e;
            this.fileService.fileUpload({
                'type': type,
                'fileType': 'NORMAL',
            }, this.onFileChanged).subscribe(data => {
                if (data) {
                    this.imageId = data.data;
                    this.updatRefrralGvtId(data.data);

                } else {
                    this.alertService.showErrors('File Not Uploaded!');
                }

            }, error => {
                this.alertService.showErrors('Something went wrong, please try again');
            });
        }
    }
    updatRefrralGvtId(data: any) {
        const params = {
            govt_proof_file_id: data?.id
        }
        this.authService.uploadPartnerGvtId(params).subscribe(response => {
            this.selectedUser = {
                ...this.selectedUser,
                govt_proof_url: data?.url,
            };
        })
    }


    updateReferralProfile(data: any) {
        const params = {
            profile_file_id: data?.id
        }
        this.authService.updateSelfPassword(params).subscribe(response => {
            this.getReferral();
            this.profileDataService.sendMessage(data?.url);
        })
    }

    // submit() {
    //     const params = {
    //         'name': this.updateEmployeeForm.value.name ? this.updateEmployeeForm.value.name : '',
    //         'username': this.updateEmployeeForm.value.username ? this.updateEmployeeForm.value.username : '',
    //         'email': this.updateEmployeeForm.value.email ? this.updateEmployeeForm.value.email : '',
    //         'contact': this.updateEmployeeForm.value.contact ? this.updateEmployeeForm.value.contact : '',
    //         'address': this.updateEmployeeForm.value.address ? this.updateEmployeeForm.value.address : '',
    //         'profile_image': this.imageId ? this.imageId.id : this.selectedUser.profile_image,
    //     };
    //     this.authService.update(params).subscribe(data => {
    //         if (this.imageId) {
    //             this.dataService.sendMessage(this.imageId.url);
    //         }
    //     }, error => {
    //         this.alertService.showErrors(error.error.errorDetails);
    //     })
    // }

    openUpdatePasswordModal(content: any) {
        this.modalService.open(content, {size: 'md', centered: true});
    }

    updatePassword() {
        if (!this.updatePasswordForm.valid) {
            this.alertService.showErrors('All Fields Are Required');
            return;
        }
        const params = {
            'currentPassword': this.updatePasswordForm.value?.currentPassword ? this.updatePasswordForm.value?.currentPassword : '',
            'newPassword': this.updatePasswordForm.value?.newPassword ? this.updatePasswordForm.value?.newPassword : '',
            'confirmPassword': this.updatePasswordForm.value?.confirmPassword ? this.updatePasswordForm.value?.confirmPassword : '',
        }
        if (params.newPassword !== params.confirmPassword || params.newPassword === params.currentPassword) {
            this.alertService.showErrors('Confirm Password Not Matched');
            return;
        }
        this.userService.updatePassword(params).subscribe(data => {
            this.closeModal();
            this.updatePasswordForm.reset();
        }, error => {
            this.alertService.showErrors(error.error.errorDetails);
        });
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    getReferral() {
        this.authService.self().subscribe(data => {
            if (data) {
                this.selectedUser = data;
                this.profileForm.patchValue({
                    name: data?.name,
                    email: data?.email,
                    contact: data?.contact,
                    address: data?.address,
                    bank_registered_name: data?.bank_registered_name,
                    bank_account_number: data?.bank_account_number,
                    bank_name: data?.bank_name,
                    bank_ifsc_code: data?.bank_ifsc_code,
                    bank_upi_id: data?.bank_upi_id,
                })
            }
        })
    }

    updateProfile() {
        if (!this.profileForm.valid) {
            this.alertService.showErrors('All fields Are Required');
            return;
        }

        const params = {
            name: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().name && this.profileForm.getRawValue().name,
            email: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().email && this.profileForm.getRawValue().email,
            contact: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().contact && this.profileForm.getRawValue().contact,
            address: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().address && this.profileForm.getRawValue().address,
            bank_registered_name: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().bank_registered_name && this.profileForm.getRawValue().bank_registered_name,
            bank_account_number: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().bank_account_number && this.profileForm.getRawValue().bank_account_number,
            bank_name: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().bank_name && this.profileForm.getRawValue().bank_name,
            bank_ifsc_code: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().bank_ifsc_code && this.profileForm.getRawValue().bank_ifsc_code,
            bank_upi_id: this.profileForm && this.profileForm.getRawValue() && this.profileForm.getRawValue().bank_upi_id && this.profileForm.getRawValue().bank_upi_id,
        }

        this.authService.update(params).subscribe(data => {
            console.log(data)
            this.getReferral();
        })
    }
}
